/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

/* You can add global styles to this file, and also import other style files */
*{
  margin: 0px 0px;
  padding: 0px 0px;
}
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}
.scroll-y{
  overflow-x: scroll;
}
a{
  color: black;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}




/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

